import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import { IRenderElement } from "../MikeVisualizer/lib/IMikeVisualizerModels";
import { AUTO_MESH_ID } from "../components/Viewer";

export interface ILegendState 
{
  layerGroups: Array<ILayerGroup>;
  elevationName: ""; 
  layerConfig: ILayerConfig;
  loadingLayerConfig: boolean;
}

export interface ILegendLayer {
  title: string;
  id: string;
  visible: boolean;
  isTwoD:  boolean;
  loaded: boolean;
  order: number;
  loading?: boolean;
  color?: Array<number>;
  groupTitle: string;
  canDelete?: boolean;
  canBeLoadedByUser?: boolean;
}
export interface ILayerGroup {
  title: string;
  hideAllLayers: boolean;
  layer: Array<ILegendLayer>;
  uploadType?: string;
}

export interface ILayerConfig {
  hiddenBackgroundLayers?: Array<string>;
}

export const meshAndPointsGroup = "Mesh and points of interest"
export const meshInputLayers = "Mesh input layers"
export const backgroundLayers = "Background layers"
export const POINT_LAYER_ID = 'points';

export const MESH_LAYER_ID = 'mesh';

export const MESH_BOUNDARY_LAYER_ID = 'meshboundary';

const initialLayerGroups: Array<ILayerGroup> = [ 
  {
    title: backgroundLayers,
    hideAllLayers: false,
    layer: [],
    uploadType: 'background' //DATASETS.BACKGROUND
  },
  {
    title: meshAndPointsGroup,
    hideAllLayers: false,
    layer: [
      { groupTitle: meshAndPointsGroup, title: "Points", id: POINT_LAYER_ID, visible: false,isTwoD: true,loaded: false, order: 3},    
      { groupTitle: meshAndPointsGroup, title: "Boundaries", id: MESH_BOUNDARY_LAYER_ID, visible: false, isTwoD: true, loaded: false, order: 1},    
      { groupTitle: meshAndPointsGroup, title: "Mesh", id: MESH_LAYER_ID, visible: false, isTwoD: false, loaded: false, order: 0}, 
      // { title: "Central points", id: MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID, visible: true, isTwoD: true, loaded: false}
    ]
  }
]

const initialLayerConfig = {
  hiddenBackgroundLayers: Array<string>()
}

const initState = 
{ 
  layerGroups: initialLayerGroups,
  elevationName: "",
  layerConfig: initialLayerConfig,
  loadingLayerConfig: false
}

const legend = (state = initState, action: IAction) => {
  const {    
    deleteData,
    delete2DData
   } = MikeVisualizerLib
  switch (action.type) {  
    case ActionType.LAYERCONFIG_LOADING: {
      return {...state, loadingLayerConfig: action.data}
    }
    case ActionType.LEGENDGROUP_SET_LAYERS_LOADED: {
      const groupTitle = action.data;
      const layerGroups = state.layerGroups;
      const layerGroup = layerGroups.find((lg: ILayerGroup) => lg.title === groupTitle);
      if (layerGroup !== undefined){
        const updatedGroup = {...layerGroup, layer: layerGroup.layer.map((l: ILegendLayer) => {return {...l, loaded: true, visible: true}})}
        return {...state, layerGroups: layerGroups.map((lg: ILayerGroup) => {return lg.title === groupTitle ? updatedGroup : lg})}
      }
      return state
    }
    case ActionType.LEGENDGROUP_REMOVE_LAYERS: {
      const {groupTitle, layerIds} = action.data;     
      const layerGroups = state.layerGroups;
      const layerGroup = layerGroups.find((lg: ILayerGroup) => lg.title === groupTitle);
      if (layerGroup !== undefined){  
        const layersToDelete = layerGroup.layer.filter((l: ILegendLayer) => layerIds.includes(l.id));
        const layers3d = layersToDelete.filter((l: ILegendLayer) => !l.isTwoD)
        layers3d.forEach((l: ILegendLayer) => {
          deleteData(l.id)
        })
        const layers2d = layersToDelete.filter((l: ILegendLayer) => l.isTwoD)
        layers2d.forEach((l: ILegendLayer) => {
          delete2DData(l.id)
        })
        const updatedGroup = {...layerGroup, layer: layerGroup.layer.filter((l: ILegendLayer) => !layerIds.includes(l.id))}
        return {...state, layerGroups: layerGroups.map((lg: ILayerGroup) => {return lg.title === groupTitle ? updatedGroup : lg})}
      }
      return state
    }
    case ActionType.LEGENDGROUP_ADD_GROUP: {
      const title = action.data
      return {...state, layerGroups: [...state.layerGroups, {  title, hideAllLayers: false, layer: []}]}
    }
    case ActionType.LEGENDGROUP_UPDATE_LAYER: {
      const {groupTitle, layer} = action.data;
      const layerGroups = state.layerGroups;
      const layerGroup = layerGroups.find((lg: ILayerGroup) => lg.title === groupTitle);
      if (layerGroup !== undefined){
        const layerExists = layerGroup.layer.find((l: ILegendLayer) => l.id === layer.id)
        if (layerExists){
          const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return l.id === layer.id ? layer : l}  )
          const updatedGroup = {...layerGroup, layer: updatedLayers}
          const updatedGroups = layerGroups.map((lg: ILayerGroup) => {return lg.title === groupTitle ? updatedGroup : lg})          
          return {...state, layerGroups: updatedGroups}
        }        
      }
      return state
    }
    case ActionType.LEGENDGROUP_ADD_LAYER: {
      const {groupTitle, layer} = action.data;
      const layerGroups = state.layerGroups;
      const layerGroup = layerGroups.find((lg: ILayerGroup) => lg.title === groupTitle);
      if (layerGroup !== undefined){
        const layerExists = layerGroup.layer.find((l: ILegendLayer) => l.id === layer.id)
        if (layerExists === undefined){
          const updatedGroup = {...layerGroup, layer: [layer, ...layerGroup.layer]}
          const updatedGroups = layerGroups.map((lg: ILayerGroup) => {return lg.title === groupTitle ? updatedGroup : lg})          
          return {...state, layerGroups: updatedGroups}
        }        
      }
      return state
    }

    case ActionType.LEGENDGROUP_ADD_LAYERS: {
      const {groupTitle, layers} = action.data;
      const layerGroups = state.layerGroups;
      const layerGroup = layerGroups.find((lg: ILayerGroup) => lg.title === groupTitle);
      if (layerGroup !== undefined){        
        const updatedGroup = {...layerGroup, layer: layers}
        const updatedGroups = layerGroups.map((lg: ILayerGroup) => {return lg.title === groupTitle ? updatedGroup : lg})          
        return {...state, layerGroups: updatedGroups}
      }
      return state
    }
    case ActionType.LEGENDGROUP_RESET: {
      return {...state, layerGroups: initialLayerGroups, layerConfig: initialLayerConfig }
    }
    case ActionType.LAYERCONFIG_SET: {
      return {...state, layerConfig: action.data.layerConfig}
    }
    case ActionType.LEGENDGROUP_HIDE_LAYER: {
      const {layerGroup, layer} = action.data;
      const { getState } = MikeVisualizerLib;
      const { hidden2DElementIds } = getState();
      layer.isTwoD ?  MikeVisualizerLib.hide2DElements([...hidden2DElementIds, layer.id]) :   
      MikeVisualizerLib.hideElement(layer.id)
      const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return l.id === layer.id ? {...l, visible: false} : l}  )
      return {...state, layerGroups: state.layerGroups.map((l: ILayerGroup) => {return l.title === layerGroup.title ? {...layerGroup, layer: updatedLayers} : l})}
    }

    case ActionType.LEGENDGROUP_HIDE_LAYER_BY_NAME: {
      const {layerGroupName, layerNames} = action.data
      const { getState } = MikeVisualizerLib;     
      const layerGroup = state.layerGroups.find((l: ILayerGroup) => l.title === layerGroupName)
      if (layerGroup === undefined){
        return state;
      }
      const layers = layerGroup.layer.filter((l: ILegendLayer) => layerNames.includes(l.id))
      if (layers.length === 0){
        return state;
      }
      const twoDLayers = layers.filter((l: ILegendLayer) => l.isTwoD)
      const threeDLayers = layers.filter((l: ILegendLayer) => !l.isTwoD)
      const { hidden2DElementIds } = getState();
      twoDLayers.length > 0 &&  MikeVisualizerLib.hide2DElements(hidden2DElementIds.concat(twoDLayers.map((l: ILegendLayer) => l.id))) 
      threeDLayers.length > 0 && MikeVisualizerLib.hideElements(threeDLayers.map((l: ILegendLayer) => l.id))
      const ids = layers.map((l: ILegendLayer) => l.id)
      const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return ids.includes(l.id) ? {...l, visible: false} : l}  )
      return {...state, layerGroups: state.layerGroups.map((l: ILayerGroup) => {return l.title === layerGroup.title ? {...layerGroup, layer: updatedLayers} : l})}
    }

    case ActionType.RESET_AUTO_MESH: {
      const interpolating = action.data         
      const inputLayerGroup = state.layerGroups.find((lg: ILayerGroup) => lg.title === meshInputLayers)
      const backgroundLayerGroup = state.layerGroups.find((lg: ILayerGroup) => lg.title === backgroundLayers)
      const initialMeshAndPointsGroup = initialLayerGroups.find((lg: ILayerGroup) => lg.title === meshAndPointsGroup) 
      if (inputLayerGroup !== undefined && !interpolating){
        const layers = inputLayerGroup.layer.filter((l: ILegendLayer) => l.id !== AUTO_MESH_ID)
        return {...state, layerGroups: [backgroundLayerGroup, initialMeshAndPointsGroup, {...inputLayerGroup, layer: layers}]}
      }
      else{
        return {...state, layerGroups: [backgroundLayerGroup, initialMeshAndPointsGroup, inputLayerGroup]}
      }
    }
   
    case ActionType.LEGENDGROUP_SHOW_LAYER: {
      const {layerGroup, layer} = action.data;
           
      if (layer.isTwoD)  {
        const { getState, show2DElements } = MikeVisualizerLib;
        const { hidden2DElementIds, rendered2DElements } = getState();
        const newHidden = hidden2DElementIds.filter((id: string) => id !== layer.id);
        const renderedIds = rendered2DElements.map((re: IRenderElement) => re.id)
        const newRenderedIds = renderedIds.filter((id: string) => !newHidden.includes(id))
        show2DElements(newRenderedIds)  
      }
      else{
        MikeVisualizerLib.showElement(layer.id)
      }   
      
      const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return l.id === layer.id ? {...l, visible: true} : l}  )
      return {...state, layerGroups: state.layerGroups.map((l: ILayerGroup) => {return l.title === layerGroup.title ? {...layerGroup, layer: updatedLayers} : l})}
    }
    case ActionType.LEGENDGROUP_HIDE_ALL: {
      const {    
        hideElements,
        hide2DElements
       } = MikeVisualizerLib
      const layerGroup = action.data
      const title = layerGroup.title
      const index = state.layerGroups.findIndex((l: ILayerGroup) => l.title === title)
      if (index !== -1){
        const layer = layerGroup.layer;
        const twoDLayerIds = layer.filter((l: ILegendLayer) => l.isTwoD === true).map((l: ILegendLayer) => l.id);
        const threeDLayerIds = layer.filter((l: ILegendLayer) => l.isTwoD === false).map((l: ILegendLayer) => l.id);
        hideElements(threeDLayerIds);
        hide2DElements(twoDLayerIds);
        const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return {...l, visible: false}}  )
        const updatedLayerGroup = {...layerGroup, hideAllLayers: true, layer: updatedLayers}
        return {...state, layerGroups: state.layerGroups.map((l: ILayerGroup) => {return l.title === updatedLayerGroup.title ? updatedLayerGroup : l})}
      }
      return state
    }
    case ActionType.LEGENDGROUP_SHOW_ALL: {
      const {    
        showElements,
        show2DElements
       } = MikeVisualizerLib
      const layerGroup = action.data
      const title = layerGroup.title
      const index = state.layerGroups.findIndex((l: ILayerGroup) => l.title === title)
      if (index !== -1){
        const layer = layerGroup.layer;
        const twoDLayerIds = layer.filter((l: ILegendLayer) => l.isTwoD === true).map((l: ILegendLayer) => l.id);
        const threeDLayerIds = layer.filter((l: ILegendLayer) => l.isTwoD === false).map((l: ILegendLayer) => l.id);
        showElements(threeDLayerIds);
        show2DElements(twoDLayerIds);
        const updatedLayers: Array<ILegendLayer> = layerGroup.layer.map((l: ILegendLayer) => {return {...l, visible: true}}  )
        const updatedLayerGroup = {...layerGroup, hideAllLayers: false, layer: updatedLayers}
        return {...state, layerGroups: state.layerGroups.map((l: ILayerGroup) => {return l.title === updatedLayerGroup.title ? updatedLayerGroup : l})}
      }
      return state
    }    
    default:
          return state;
  }
};

export default legend