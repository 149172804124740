/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import {  Collapse } from '@mui/material';
import IProjection from './model/IProjection';
import Button from '@mui/material/Button';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

export const buttonStyle = css`
 font-size: 0.8em;
  height: ${mikeSharedTheme.spacing(0.5)};
`;

export const labelStyle = css`
 font-weight: ${mikeSharedTheme.typography.fontWeightBold};
`;

export const detailStyle = css`
  font-size: 0.8em;
`;

export const codeStyle = css`
  font-size: 0.8em;
`;

export const noDetailStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const sectionStyle = css`
  white-space: normal;
  word-break: break-all;
`;

export const expandedSectionStyle = css`
  max-width: 50vw;
`;

interface IProps {
  item: IProjection;
}

const formatWkt = (wkt: string) => wkt.trim().replace(/&quot;/g, '"');

const MikeProjectionDetails = (props: IProps) => {  
  const { item } = props;

  const [open, setOpen] = useState(false);

  const changeOpen = (openChanged: boolean) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpen(openChanged);
  };

  const wkt = formatWkt(item.wkt);
  const proj4String = item.proj4String.trim();

  return (
    <div>
      {open ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div>
            <div css={detailStyle}>
              <label css={labelStyle}>wkt</label>
              <section css={[sectionStyle, expandedSectionStyle].join(' ')}>
                {wkt}
              </section>
            </div>

            <div css={detailStyle}>
              <label css={labelStyle}>proj4String</label>
              <section css={[sectionStyle, expandedSectionStyle].join(' ')}>
                {proj4String}
                <Button css={buttonStyle} onClick={changeOpen(false)}>
                  less
                </Button>
              </section>
            </div>
          </div>
        </Collapse>
      ) : (
        <div css={noDetailStyle}>
          <div css={codeStyle}>Code: {item.id}</div>
          <Button css={buttonStyle} onClick={changeOpen(true)}>
            more
          </Button>
        </div>
      )}
    </div>
  );
};

export default MikeProjectionDetails;
